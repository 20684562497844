<template>
  <div>
    <div class="vs-row hero" :style="imageStyleObject">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Club Details</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="vs-row flex border mt-3 rounded p-5">
        <div class="vs-col lg:w-1/6 md:w-1/5 sm:w-1/4 w-full">
          <img class="w-32" :src="club.logo" alt="">
        </div>
        <div class="vs-col lg:w-5/6 md:w-4/5 sm:w-3/4 w-full xl:pl-0 sm:pl-5 sm:pt-0 pt-5 self-center">
          <p class="mb-1">{{club.name}}</p>
          <star-rating class="mb-2"
            :border-width="3" 
            :star-size="15" 
            :rating="3" 
            :read-only="true" 
            :rtl="false" 
            :show-rating="false" 
            inactive-color="#FFF" 
            active-color="#F89416" 
            border-color="#F89416" 
            :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" />
          <div class="flex divide-x divide-gray-300">
            <div class="text-center pr-3">
              <p>Activities</p>
              <p class="text-gray-400">{{club.activity_count}}</p>
            </div>
            <div class="text-center px-3">
              <p>Reviews</p>
              <p class="text-gray-400">{{club.review.length}}</p>
            </div>
            <div class="text-center pl-3">
              <p>Photos</p>
              <p class="text-gray-400">{{club.gallery.length}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="vs-row mt-7">
        <div class="vs-col sm:w-1/2 w-full">
          <p class="text-lg">{{club.name}}</p>
          <i class="el-icon-location text-blue"></i><span class="time mx-1 text-blue">1 Location</span>
        </div>
        <div class="vs-col sm:w-1/2 w-full sm:mt-0 mt-2 flex sm:justify-end">
          <vs-button class="btn" color="#3a3a3a" text-color="#fff" icon="favorite">Save</vs-button>
          <vs-button class="btn ml-3" color="#00DCDC" text-color="#fff" icon="reply">Share</vs-button>
        </div>
      </div>
      <div class="divide-y divide-gray-300">
        <div class="vs-row mb-8 pt-6">
          <div v-html="club.description"></div>
        </div>
        <div>
          <div class="vs-row flex pt-5">
            <p class="vs-col w-1/2">Activities</p>
            <p class="vs-col w-1/2 text-blue flex justify-end">See All</p>
          </div>
          <div class="vs-row flex">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="(o, index) in 9" :key="o" :offset="index > 0 ? 2 : 0">
                <el-card class="text-left w-96" :body-style="{ padding: '0px' }">
                  <div class="cardBody">
                    <span class="material-icons btn">favorite</span>
                    <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
                  </div>
                  <div class="footer" style="padding: 14px;">
                    <span>The Hill-top Party in English</span>
                    <div class="mt-1">
                      <i class="el-icon-location text-blue"></i>
                      <span class="time mx-1 text-sm text-gray-400">At Home | 2-12 Years | Books</span>
                      <div class="vs-row mx-1 bottom flex">
                        <div class="vs-col w-3/4 self-center text-sm">
                          <span class="text-blue">Price: </span><span class="text-orange">2.500 KWD</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
      <div>
        <vs-tabs>
          <vs-tab label="Gallery">
            <div class="vs-row pt-5">
              <vueper-slides
                ref="vueperslides1"
                :touchable="false"
                fade
                :autoplay="false"
                :bullets="false"
                @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                fixed-height="400px">
                <vueper-slide style="position: flex;"
                  v-for="(slide, i) in club.gallery"
                  :key="i"
                  :image="slide.photo_name">
                </vueper-slide>
              </vueper-slides>
              <vueper-slides
                class="no-shadow thumbnails"
                ref="vueperslides2"
                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                :visible-slides="club.gallery.length"
                fixed-height="75px"
                :bullets="false"
                :touchable="false"
                :gap="2.5"
                :arrows="false">
                <vueper-slide
                  v-for="(slide, i) in club.gallery"
                  :key="i"
                  :image="slide.photo_name"
                  @click.native="$refs.vueperslides2.goToSlide(i)">
                </vueper-slide>
              </vueper-slides>
            </div>
          </vs-tab>
          <vs-tab label="Reviews">
            <div class="divide-y divide-gray-100 mt-10">
              <div class="vs-row pt-7" v-for="review in club.review" :key="review">
                <div class="vs-row w-full pt-1">
                  <div class="vs-col w-1/2">
                    <p class="text-lg">{{review.user_name}}</p>
                    <p class="text-lg">Malek Ibrahim</p>
                    <star-rating 
                      :border-width="3" 
                      :star-size="13" 
                      :rating="4" 
                      :read-only="true" 
                      :rtl="false" 
                      :show-rating="false" 
                      inactive-color="#FFF" 
                      active-color="#F89416" 
                      border-color="#F89416" 
                      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" />
                  </div>
                  <div class="vs-col w-1/2 flex justify-end">
                    <p class="text-gray-400">{{review.created.split(' ')[0]}}</p>
                  </div>
                </div>
                <div class="vs-row w-full pb-7 pt-1">
                  {{review.review_desc}}
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import StarRating from 'vue-star-rating'
import axios from '../axios.js'

export default {
  components: { Swiper, SwiperSlide, VueperSlides, VueperSlide, StarRating },
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      activeName: 'first',
      value: 3,
      club: {
        review: [],
        gallery: []
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Club',
          url: 'home',
        },
        {
          title: 'Club Details',
          active: true
        }
      ],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    }
  },
  methods: {
    loadClubDetail () {
      this.$vs.loading()
      axios.post(`${localStorage.getItem('access_token') && localStorage.getItem('loggedIn') ? 
      'v1/user/getClubDetails' : 'v1/getClubDetailsWeb'}`, {club_id: this.$route.params.clubID}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.club = response.data.payload
          this.$vs.loading.close()
        }
      })
    }
  },
  computed: {
    imageStyleObject() {
      return {
        backgroundImage: `url(${this.club.banner})`
      }
    }  
  },
  created () {
    this.loadClubDetail()
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  height: 400px;
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .swiper-button-prev, .swiper-button-next {
    background-color: #F89416;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    color: #fff !important;
    font-size: small;
  }
}
</style>

<style scoped>
.image {
  width: 100%;
  height: 200px;
  display: block;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color: gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.btn {
  padding: 5px 15px;
  border-radius: 2px;
}
@media (max-width: 768px) {
  .btn {
    padding: 3px 10px;
  }
}
</style>